import React from "react"
import { graphql } from "gatsby"
import Parser from "html-react-parser"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image";

// import app components
import { Edges, Hero, Spacer, Textarea, VideoImage } from "components"
import * as theme from "theme"
import WaveIcon from "icons/wave-2.svg"
import { colors } from "theme"

export default function DesignTemplate(props) {
  const {
    data: {
      page: {
        templateDesign: {
          designHero: { image, headline, text },
          designFeatured: { contentRight, featImage, featVideo },
          designMediaGroupBottom,
          designMediaGroupTop,
          designMediaGroupRight,
          designOverlappingMedia,
          designFeatures,
          contentBreakContent
        }
      }
    }
  } = props

  return <>
    <Hero border={true} image={image} headline={headline} />

    <FeaturedContainer>
      <Spacer pb={{ xs: 50, sm: 80, md: 115 }}>
        <Edges size="cont">
          <Grid container>
            <Grid item md={2} />
            <Grid item md={6}>
              <Spacer pt={40} pb={{ xs: 40, sm: 60 }}>
                <FeatureIntroText>{Parser(text)}</FeatureIntroText>
              </Spacer>
            </Grid>
          </Grid>
          <Spacer mb={20} />

          <Grid container alignItems="center">
            <Grid item xs={12} md={5}>
              <FeaturedLeft>
                <VideoImage
                  image={featImage}
                  responsive={true}
                  youtubeUrl={featVideo}
                />
              </FeaturedLeft>
            </Grid>

            <Grid item md={1} />

            <Grid item md={5}>
              <FeaturedRight>
                <Textarea content={contentRight} />
              </FeaturedRight>
            </Grid>
          </Grid>
        </Edges>
      </Spacer>
    </FeaturedContainer>

    <StyledEdges size="cont">
      <WaveContainer>
        <WaveIcon />
      </WaveContainer>
    </StyledEdges>

    <DesignFeatures>
      <Edges size="cont">
        <Spacer pt={{ xs: 50, md: 115 }} pb={{ xs: 60, sm: 100 }}>
          {designFeatures &&
            designFeatures.map((o, i) => {
              const even = i % 2 === 0
              return (
                <Grid key={i} container className="DesignFeatures__row">
                  <Grid item md={even ? 2 : 1} />
                  <Grid item md={5}>
                    <Textarea content={o.textContent} />
                  </Grid>
                  <Grid item md={1} />
                  <Grid item xs={12} md={even ? 4 : 5}>
                    <Spacer mt={{ xs: 40, sm: 80, md: 0 }} />
                    <Textarea content={o.mediaContent} />
                  </Grid>
                </Grid>
              )
            })}
        </Spacer>
      </Edges>
    </DesignFeatures>

    <OverlappingMedia>
      <Edges size="cont">
        <Grid container spacing={1}>
          <Grid item md={2} />
          {designOverlappingMedia &&
            designOverlappingMedia.map((o, i) => {
              return (
                <Grid key={i} item xs={12} md={4}>
                  <OverlapMediaCont>
                    {o.imageOrVideo === "image" && (
                      <GatsbyImage image={o.image?.localFile?.childImageSharp?.gatsbyImageData} />
                    )}
                    {o.imageOrVideo === "video" && (
                      <VideoImage
                        image={o.videoPoster}
                        responsive={true}
                        youtubeUrl={o?.video}
                      />
                    )}
                  </OverlapMediaCont>
                </Grid>
              );
            })}
        </Grid>
        <Spacer
          mt={{ xs: 40, sm: 60, md: 75 }}
          pb={{ xs: 40, sm: 80, md: 115 }}
        >
          <Grid container>
            <Grid item md={2} />
            <Grid item md={5}>
              <Textarea content={contentBreakContent} />
            </Grid>
          </Grid>
        </Spacer>
      </Edges>
    </OverlappingMedia>

    <MediaGroup>
      <Edges size="cont">
        <Grid container spacing={1}>
          <Grid item md={1} />
          <Grid item xs={12} md={6}>
            {designMediaGroupTop && (
              <>
                <MediaGroupImgCont variant="top">
                  <MediaGroupImgContLeft>
                    <GatsbyImage
                      image={designMediaGroupTop.smallImage.localFile?.childImageSharp?.gatsbyImageData} />
                    <GatsbyImage
                      image={designMediaGroupTop.smallImage2.localFile?.childImageSharp?.gatsbyImageData} />
                  </MediaGroupImgContLeft>
                  <MediaGroupImgContRight>
                    <Spacer mt={{ xs: 5, md: 0 }} />
                    <GatsbyImage
                      image={designMediaGroupTop.mediumImage.localFile?.childImageSharp?.gatsbyImageData} />
                  </MediaGroupImgContRight>
                </MediaGroupImgCont>
                <Spacer mt={{ xs: 0, md: 65 }} />
                <MediaGroupImgCont variant="left">
                  <MediaGroupImgSolo>
                    <GatsbyImage
                      image={designMediaGroupBottom.largeImage.localFile?.childImageSharp?.gatsbyImageData} />
                  </MediaGroupImgSolo>
                </MediaGroupImgCont>
              </>
            )}
          </Grid>
          <StyledGridItem item md={1} />
          <Grid item xs={12} md={4}>
            <MediaGroupImgContTop>
              <Spacer pt={{ xs: 0, md: 30 }} />
              <GatsbyImage
                image={designMediaGroupRight.mediumImage.localFile?.childImageSharp?.gatsbyImageData} />
            </MediaGroupImgContTop>
            <MediaGroupImgContBottom>
              <GatsbyImage
                image={designMediaGroupRight.smallImage.localFile?.childImageSharp?.gatsbyImageData} />
              <GatsbyImage
                image={designMediaGroupRight.smallImage2.localFile?.childImageSharp?.gatsbyImageData} />
            </MediaGroupImgContBottom>
          </Grid>
        </Grid>
      </Edges>
    </MediaGroup>
  </>;
}

const FeaturedContainer = styled.div`
  background: #000;
  color: ${theme.colors.text.light};
  position: relative;
  margin-bottom: -1px;
`

const FeatureIntroText = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
`

const FeaturedLeft = styled.div``
const FeaturedRight = styled.div``

const StyledEdges = styled(Edges)`
  position: relative;
`

const WaveContainer = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;

  @media (min-width: 768px) {
    width: 50vw;
  }

  svg {
    max-width: 100%;
    width: 100%;
  }
`

const DesignFeatures = styled.div`
  background: radial-gradient(
    69.12% 69.29% at 12.66% -27.53%,
    #000000 41.89%,
    #252525 100%
  );
  color: ${colors.text.light};

  .DesignFeatures__row {
    padding-bottom: 40px;

    @media (min-width: 768px) {
      padding-bottom: 80px;
    }

    &:nth-of-type(odd) {
      flex-flow: row-reverse wrap;
    }
  }
`

const OverlappingMedia = styled.div`
  margin-top: -80px;
  padding-bottom: 100px;
`

const OverlapMediaCont = styled.div``

const MediaGroup = styled.div`
  background: #252525;
`

const MediaGroupImgCont = styled(({ variant, ...rest }) => <div {...rest} />)`
  display: flex;
  flex-flow: row wrap;
  ${props =>
    props.variant === "left" &&
    `@media (min-width: 1200px) {
      left: -105px;
    }`}
  position: relative;
  ${props =>
    props.variant === "top" &&
    `top: -85px;
    margin-bottom: -80px;
    `}
  width: 100%;
`

const MediaGroupImgContLeft = styled.div`
  width: 100%;

  > div {
    &:nth-child(2) {
      padding-top: 3px;
      margin-top: 5px;
    }
  }

  @media (min-width: 768px) {
    padding-right: 5px;
    width: 33.333%;

    &:nth-child(1) {
      margin-top: 5px;
    }
  }

  @media (min-width: 960px) {
    &:nth-child(1) {
      margin-top: 0px;
    }
  }
`

const MediaGroupImgSolo = styled.div`
  width: 100%;
`

const MediaGroupImgContRight = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 66.666%;
  }
`

const MediaGroupImgContTop = styled.div`
  padding-bottom: 5px;
`

const MediaGroupImgContBottom = styled.div`
  display: flex;
  flex-flow: row wrap;

  > div {
    width: 100%;

    &:nth-child(2) {
      margin-top: 5px;
    }
  }

  @media (min-width: 768px) {
    > div {
      width: calc(50% - 2.5px);

      &:nth-child(2) {
        margin-left: 5px;
        margin-top: 0px;
      }
    }
  }
`

const StyledGridItem = styled(Grid)`
  @media (max-width: 768px) {
    padding: 0px !important;
  }
`

export const CollectionQuery = graphql`query DesignTemplate($id: String!) {
  page: wpPage(id: {eq: $id}) {
    templateDesign {
      designFeatured {
        contentRight
        featVideo
        featImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
      designHero {
        headline
        text
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
      designMediaGroupBottom {
        singleOrGroup
        smallImage2 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
            }
          }
        }
        smallImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
            }
          }
        }
        mediumImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
            }
          }
        }
        largeImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
      designMediaGroupTop {
        singleOrGroup
        smallImage2 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
            }
          }
        }
        smallImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
            }
          }
        }
        mediumImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
            }
          }
        }
        largeImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
      designOverlappingMedia {
        video
        videoPoster {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        imageOrVideo
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
      designMediaGroupRight {
        singleOrGroup
        smallImage2 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
            }
          }
        }
        smallImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
            }
          }
        }
        mediumImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
            }
          }
        }
        largeImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
      designFeatures {
        mediaContent
        textContent
      }
      contentBreakContent
    }
  }
}
`
